/*
 * _wordpress-helpers.scss
 */

/*--------------------------------------------------------------
Alignment
--------------------------------------------------------------*/

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

.has-text-align-left {
  text-align: left;
}

.has-text-align-center {
  text-align: center;
}

.has-text-align-right {
  text-align: right;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/*--------------------------------------------------------------
Alignment (Gutenberg Blocks)
--------------------------------------------------------------*/

$scrollbar-width: 17px;

.alignwide {
  max-width: 100vw;

  @each $key, $value in $grid-breakpoints {
    @include breakpoint(above, $key) {
      // margin-left: calc( (-100vw / 2 ) + ( 100% / 2 ) + #{map-get($spacing-horizontal, $key)} + ( var(--scrollbar-width) / 2 ) );
      // margin-right: calc( (-100vw / 2 ) + ( 100% / 2 ) + #{map-get($spacing-horizontal, $key)} + ( var(--scrollbar-width) / 2 ) );
      margin-left: calc( (-100vw / 2 ) + ( 100% / 2 ) + #{map-get($spacing-horizontal, $key)} );
      margin-right: calc( (-100vw / 2 ) + ( 100% / 2 ) + #{map-get($spacing-horizontal, $key)} );
    }
  }

  @include breakpoint(above, $site-max-width) {
    margin-left: calc( -#{$site-max-width} / 2 + 100% / 2 + #{map-get($spacing-horizontal,  $largest-breakpoint)} ) !important;
    margin-right: calc( -#{$site-max-width} / 2 + 100% / 2 + #{map-get($spacing-horizontal,  $largest-breakpoint)} ) !important;
  }

  @include breakpoint(below, $two-column-breakpoint) {
    // margin-left: calc( ( -100vw / 2 ) + ( 100% / 2 ) + ( var(--scrollbar-width) / 2 ) );
    // margin-right: calc( ( -100vw / 2 ) + ( 100% / 2 ) + ( var(--scrollbar-width) / 2 ) );
    margin-left: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
    margin-right: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
    // overflow-x: hidden;
  }
}

/*.alignfull {
  max-width: $site-max-width;
  margin-left: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
  margin-right: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
  overflow-x: hidden;

  @include breakpoint(above, $site-max-width) {
    margin-left: calc( -#{$site-max-width} / 2 + 100% / 2 ) !important;
    margin-right: calc( -#{$site-max-width} / 2 + 100% / 2 ) !important;
  }
}*/

.alignfull {
  max-width: $site-max-width;
  //mobile
  margin-left: calc( ( -105vw / 2 ) + ( 100% / 2 ) + ( var(--scrollbar-width) / 2 ) );
  margin-right: calc( ( -105vw / 2 ) + ( 100% / 2 ) + ( var(--scrollbar-width) / 2 ) );
  overflow-x: hidden;

  @include breakpoint(above, $site-max-width) {
    margin-left: calc( -#{$site-max-width} / 2 + 100% / 2 ) !important;
    margin-right: calc( -#{$site-max-width} / 2 + 100% / 2 ) !important;
  }

}

// IE10+ specific styles
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .alignwide {

    @each $key, $value in $grid-breakpoints {
      @include breakpoint(above, $key) {
        margin-left: calc( (-100vw / 2 ) + ( 100% / 2 ) + #{map-get($spacing-horizontal, $key)} );
        margin-right: calc( (-100vw / 2 ) + ( 100% / 2 ) + #{map-get($spacing-horizontal, $key)} );
      }
    }

    @include breakpoint(below, $two-column-breakpoint) {
      margin-left: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
      margin-right: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
    }
  }

  .alignfull {
    margin-left: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
    margin-right: calc( ( -100vw / 2 ) + ( 100% / 2 ) );
  }
}


/*--------------------------------------------------------------
Captions
--------------------------------------------------------------*/

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}


/*--------------------------------------------------------------
Accessibility
--------------------------------------------------------------*/

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}
