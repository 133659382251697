////
/// Generates CSS utility classes to hide elements at different breakpoints
////

.hide {
  display: none;
}

.hide-visually {
  @include hide-visually;
}

@each $breakpoint-name, $size in $breakpoints {
  // Hide completely

  @include breakpoint('below', $breakpoint-name) {
    .hide-below-#{$breakpoint-name} {
      display: none;
    }
  }

  @include breakpoint('above', $breakpoint-name) {
    .hide-above-#{$breakpoint-name} {
      display: none;
    }
  }

  // ---------------------------------------------------------------------------

  // Hide visually / accessibly

  @include breakpoint('below', $breakpoint-name) {
    .hide-visually-below-#{$breakpoint-name} {
      @include hide-visually;
    }
  }

  @include breakpoint('above', $breakpoint-name) {
    .hide-visually-above-#{$breakpoint-name} {
      @include hide-visually;
    }
  }
}
