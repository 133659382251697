/*
 * _container.scss
 */

.container {
    // @include make-container();
    // padding-left: 30px;
    // padding-right: 30px;

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            max-width: 1230px;
            padding-left: map-get($spacing-horizontal, $key);
            padding-right: map-get($spacing-horizontal, $key);
        }
    }

    &.no-padding {
        // padding-left: 15px !important;
        // padding-right: 15px !important;
        @include responsive-padding(x);
    }
}
.container-fluid{
        &.no-padding {
        // padding-left: 15px !important;
        // padding-right: 15px !important;
        @include responsive-padding(x);
    }
}