/*
 * _main-menu.scss
 */

$menu-breakpoint: 'lg';
$navbar-height: 72px;
$navbar-font-size: 18px;

#app {
    //so mobile can not "push content down "
    padding-top: $navbar-height; // height of navbar
}

.navbar {
    // padding: 22px 0;
    @include responsive-padding(y, $scale: $spacing-header);
    // @include responsive-padding(x);
    padding-left: 0;
    padding-right: 0;

    .navbar-brand {
        width: 146px;
        height: 28px;
        padding: 0;
        // background-image: url('../images/cluejay-logo-rgb.png');
        background-size: 146px 28px;
        #mspl &{
            background-size: 100%;
            background-repeat: no-repeat;
            background-position: center;
            height: 60px;
            background-image: url('../images/store/MSPL__Logo_tagline_full_white.png');
            width: 50%;
            @include breakpoint(above, lg) {
                width: 24%;
            }
            @include breakpoint(above, xl) {
                width: 260px;
            }
        }
        @media(min-width: 660px) and (max-width: 850px) {
            height: 80px !important;
        }
        @media(min-width: 851px) and (max-width: 995px) {
            height: 90px !important;
        }
        @include breakpoint(above, xl) {
            width: 185px;
            height: 36px;
            background-size: 185px 36px;
        }

        &:hover,
        &:focus {
            // background-image: url('../images/cluejay-logo-rgb.png');
            #mspl &{
                background-image: url('../images/store/MSPL__Logo_tagline_full_white.png');
            }
        }
    }

    .navbar-toggler {
        padding: 0;
        border: none;

        .navbar-toggler-icon {
            width: 24px;
            height: 18px;
            background-image: url('../images/store/menu-close.svg');
        }

        &.collapsed .navbar-toggler-icon {
            width: 24px;
            height: 18px;
            background-image: url('../images/store/menu-toggle.svg');
        }
    }

    #navbarLeft {
        @include responsive-margin(top, $scale: $spacing-header);
        border-top: 1px solid $gray-lighter;

        @each $key, $value in $grid-breakpoints {
            @include breakpoint(above, $key) {
                margin-left: -(#{map-get($spacing-header-horizontal, $key)});
                margin-right: -(#{map-get($spacing-header-horizontal, $key)});
            }
        }

        span.icon-user {
            margin-right: 6px;
            font-size: 12px;
        }

        span.icon-power {
            margin-right: 6px;
            font-size: 16px;
            margin-left: -2px;
        }

        @include breakpoint(above, lg) {
            span.icon-power {
                margin-right: 6px;
                font-size: 12px;
                margin-left: -2px;
            }
        }
    }

    .navbar-nav {
        //mspl
        line-height: 100%;

        .nav-item {
            width: 100%;

            &:nth-last-of-type(2),
            &:last-of-type {
                display: none;
            }
        }

        .nav-item.dropdown {
            a {
                position: relative;

                &::after {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    width: 8px;
                    height: 8px;
                    border: none;
                    border-bottom: 1px solid $gray;
                    border-right: 1px solid $gray;
                    transform: translateY(-50%) rotate(45deg);
                    transition: transform $default-transition-time;

                    @each $key, $value in $grid-breakpoints {
                        @include breakpoint(above, $key) {
                            right: #{map-get($spacing-header-horizontal, $key)};
                        }
                    }
                }

                &:hover::after,
                &:focus::after {
                    border-color: $purple;
                }
            }

            &.show {
                a::after {
                    transform: translateY(-50%) rotate(225deg);
                }
            }
        }

        .nav-link {
            color:$purple;
            padding-top: 16px;
            padding-bottom: 16px;
            border-bottom: 1px solid $gray-lighter;
            text-decoration: none;
            font-size: $navbar-font-size;

            &.btn-activate-kit {}

            &:hover,
            &:focus {
                text-decoration: none;
                color: $gray;
            }

            @each $key, $value in $grid-breakpoints {
                @include breakpoint(above, $key) {
                    padding-left: #{map-get($spacing-header-horizontal, $key)};
                    padding-right: #{map-get($spacing-header-horizontal, $key)};
                }
            }

            @include breakpoint(above, lg) {
                padding-left: 12px;
                padding-right: 12px;
            }

            @include breakpoint(above, xl) {
                padding-left: 16px;
                padding-right: 16px;
            }

            @include breakpoint(above, xxl) {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    a.profile-icon.mobile {
        align-items: center;
        display: flex;
        img {
            height: 35px;
        }
        img {
            @media (max-width: 400px){
                height: 28px;
            }
            @media (max-width: 300px){
                height: 20px;
            }
        }
    }

    a.profile-icon {
        filter: saturate(0%) brightness(200%); height: 28px;
    }

    .dropdown-menu {
        margin-top: 0;
        background-color: $nav-purple;
        // border: 1px solid $gray-lighter;
        font-size: $navbar-font-size;

        a.dropdown-item {
            padding-top: 12px;
            padding-bottom: 12px;
            text-decoration: none;
            font-weight: normal;
            color: $purple;

            &:hover,
            &:focus {
                background-color: transparent;
                text-decoration: none;
                color: $gray;
            }

            @each $key, $value in $grid-breakpoints {
                @include breakpoint(above, $key) {
                    padding-left: #{map-get($spacing-header-horizontal, $key)};
                    padding-right: #{map-get($spacing-header-horizontal, $key)};
                }
            }
        }
    }

    a.btn-cart {
        position: relative;
        padding: 0 12px;
        text-decoration: none;
        font-size: 24px;
        color: $purple;
        transform: translateY(1px);

        .counter {
            position: absolute;
            top: -6px;
            left: 26px;
            display: block;
            min-width: 16px;
            width: auto;
            height: 16px;
            padding: 0 5px;
            background-color: $blue;
            border-radius: 8px;
            text-align: center;
            font-size: 9px;
            font-weight: normal;
            line-height: 1.9;
            color: $white;
        }

        &:hover,
        &:focus {
            text-decoration: none;
            color: $gray;
        }
    }
}

@include breakpoint(above, $menu-breakpoint) {

    .navbar {

        #navbarLeft {
            margin: 0;
            border-top: none;
        }

        .navbar-nav {
            .nav-link {
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;
            }

            .nav-item {
                width: auto;

                &:first-of-type .nav-link {
                    padding-left: 0;
                }

                &:last-of-type .nav-link.btn {
                    margin-left: 10px;
                    margin-right: 10px;
                }

                &:nth-last-of-type(2),
                &:last-of-type() {
                    display: block;
                }
            }

            .nav-item.dropdown {
                a {

                    &::after {
                        position: relative;
                        top: 0;
                        right: auto;
                        width: 7px;
                        height: 7px;
                        margin-left: 4px;
                        border: none;
                        border-bottom: 1px solid $gray;
                        border-right: 1px solid $gray;
                        transform: rotate(45deg);
                        transition: top $default-transition-time,
                                    transform $default-transition-time;
                    }
                }

                &.show,
                &.focus-within,
                &:hover {
                    a::after {
                        top: 4px;
                        transform: rotate(225deg);
                    }
                }

                &:focus-within {
                    a::after {
                        top: 4px;
                        transform: rotate(225deg);
                    }
                }
            }
        }

        .dropdown {

            .dropdown-menu {
                top: 140%;
                // left: -12px;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                margin-top: 18px;
                padding: 20px 0;
                border-radius: 0;
                border: none;
                white-space: nowrap; // auto width sub-menus
                opacity: 0;
                pointer-events: none;
                transition: opacity $default-transition-time ease-in-out,
                            top $default-transition-time ease-in-out;

                a.dropdown-item {
                    padding: 10px 28px;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: -12px;
                    left: 50%;
                    z-index: 1;
                    width: 0px;
                    height: 0px;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-bottom: 12px solid #EAE6EF;
                    transform: translateX(-50%);
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: -20px;
                    left: 0;
                    z-index: 0;
                    display: block;
                    width: 100%;
                    height: 20px;
                }

                // @include breakpoint(above, xl) {
                //     left: -8px;
                // }

                // @include breakpoint(above, xxl) {
                //     left: -4px;
                // }
            }

            &:hover,
            &.focus-within {
                > a {
                    color: $purple;
                }
                .dropdown-menu {
                    top: 100%;
                    opacity: 1;
                    pointer-events: auto;
                }
            }

            &:focus-within {
                > a {
                    color: $purple;
                }

                .dropdown-menu {
                    top: 96%;
                    opacity: 1;
                    pointer-events: auto;
                }
            }
        }
    }
}
