/*
 * _card-carousel.scss
 */

.card-carousel-block {
    @include responsive-padding(y);

    // .slick-list {
    //     padding: 12px 24px 36px;
    // }

    ul.slick-dots {
        padding-left: 0;
        text-align: center;

        li {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin: 0 5px;
            list-style-type: none;
            background-color: $gray;
            border-radius: 50%;
            font-size: 0;

            &.slick-active {
                background-color: $purple;
            }
        }

        @include breakpoint(above, md) {
            display: none !important;
        }
    }
}

.card-carousel-items-container {
    position: relative;
    margin-bottom: 40px;

    &::before {
        content: '';
        position: absolute;
        z-index: 0;
        top: -10%;
        left: -10%;
        width: 115%;
        height: 115%;
        background-image: url('../images/store/blob_shape.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
    }
}

.card-carousel-single-item {
    position: relative;
    display: none;
    margin: 12px 24px 36px;
    background-color: $white;
    text-align: center;

    &:first-of-type {
        display: block;
    }

    img {
        max-width: 140px;
    }
}

ul.card-carousel-navigation {
    position: relative;
    z-index: 1;
    display: none;
    padding-left: 0;
    list-style-type: none;

    li {

        button {
            position: relative;
            display: inline-block;
            margin-bottom: 28px;
            padding-left: 0;
            font-family: $font-secondary-bold;
            color: $gray;
            transition: padding $default-transition-time ease-in-out;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: -24px;
                display: inline-block;
                width: 16px;
                height: 27px;
                background-image: url('../images/store/chevron.svg');
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 16px 27px;
                opacity: 0;
                transition: left $default-transition-time ease-in-out,
                            opacity $default-transition-time ease-in-out;
            }

            &:hover,
            &:focus {
                text-decoration: none;
                color: $purple;
                outline: 0;
            }
        }

        &.active button {
            padding-left: 40px;
            color: $purple;

            &::before {
                left: 0;
                opacity: 1;
            }
        }
    }

    @include breakpoint(above, md) {
        display: block;
    }
}

