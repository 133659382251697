/*
 * _button.scss
 */

.wp-block-button {
    .wp-block-button__link {
        @extend .btn;

        // $colors: (
        //     'purple': $purple,
        //     'blue': $blue,
        //     'white': $white,
        // );

        // @each $name, $color in $colors {

        //     &.has-#{$name}-color {
        //         color: $color;
        //     }

        //     &.has-#{$name}-background-color {
        //         background-color: $color;
        //         border-color: $color;
        //     }
        // }
    }

    &.is-style-primary {
        .wp-block-button__link {
            @extend .btn-primary;
        }
    }

    &.is-style-secondary {
        .wp-block-button__link {
            @extend .btn-secondary;
        }
    }

    &.is-style-outline {
        .wp-block-button__link {
            @extend .btn-outline;
        }
    }

    &.aligncenter {
        text-align: center;
    }

    &.has-chevron {
        position: relative;
        padding-left: 40px;

        .wp-block-button__link::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            display: inline-block;
            width: 16px;
            height: 27px;
            padding-left: 24px;
            background-image: url('../images/store/chevron.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: 16px 27px;
            font-family: $font-secondary-bold;
            font-weight: bold;
            font-size: 16px;

            @include breakpoint(above, xl) {
                top: 10px;
            }
        }
    }
}
