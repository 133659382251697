/*** Fonts Configurations **/

// Import any Google, Typekit or other hosted fonts here.
// @font-face {
//   font-family: "Averta";
//   src:  url('../../fonts/averta/Averta_Regular.eot'); /* IE9 Compat Modes */
//   src:  url('../../fonts/averta/Averta_Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
//       url('../../fonts/averta/Averta_Regular.woff2') format('woff2'), /* Super Modern Browsers */
//       url('../../fonts/averta/Averta_Regular.woff') format('woff'); /* Pretty Modern Browsers */
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }



// #mspl

@import url("https://p.typekit.net/p.css?s=1&k=yyn8hkp&ht=tk&f=34095.34096.34101.34102&a=84623716&app=typekit&e=css");

@font-face {
font-family:"quasimoda";
src:url("https://use.typekit.net/af/fda6bf/00000000000000007735dee2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/fda6bf/00000000000000007735dee2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/fda6bf/00000000000000007735dee2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
font-display:auto;
font-style:normal;
font-weight:400;
}

// @font-face {
// font-family:"quasimoda";
// src:url("https://use.typekit.net/af/eba4b9/00000000000000007735dedf/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/eba4b9/00000000000000007735dedf/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/eba4b9/00000000000000007735dedf/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
// font-display:auto;font-style:italic;font-weight:400;
// }

@font-face {
font-family:"quasimoda";
src:url("https://use.typekit.net/af/4fe93d/00000000000000007735dedd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/4fe93d/00000000000000007735dedd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/4fe93d/00000000000000007735dedd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
font-display:auto;
font-style:normal;
font-weight:700;
}

// @font-face {
// font-family:"quasimoda";
// src:url("https://use.typekit.net/af/c62fe0/00000000000000007735ded2/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/c62fe0/00000000000000007735ded2/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/c62fe0/00000000000000007735ded2/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
// font-display:auto;font-style:italic;font-weight:700;
// }

.tk-quasimoda { font-family: "quasimoda",sans-serif; }

// set font variables here
// $font-primary: DIN Next LT W01 Regular, sans-serif;
$font-primary: quasimoda, sans-serif;
// $font-primary-medium: DIN Next LT W01 Medium, sans-serif;
$font-primary-medium: quasimoda, sans-serif;
// $font-secondary: Averta W01 Regular1586764, sans-serif;
$font-secondary: quasimoda, sans-serif;
// $font-secondary-bold: Averta W01 Bold1587810, sans-serif;
$font-secondary-bold: quasimoda, sans-serif;

$font-family-sans-serif: $font-primary;

$heading-font-family: $font-secondary-bold;

// Dev note: Using the Regular Averta font and a font-weight: 900; rule will break the font in Firefox
// and Safari. So that's why we need to use the Bold Averta font using $font-secondary-bold instead.


// a quick little reset first
h1, h2, h3, h4, h5, h6, p, ul, ol {
  margin-top: 0;
  margin-bottom: 0;
}

// space between ps, and uls/ps
p + p, p + h2, p + h3, p + h4, p + h5, p + h6 {
  margin-top: 0;
}

p + ul, ul + p, ul + ul,
p + ol, ol + p, ol + ol {
  margin-top:0;
}

// space between
ul + h2, ul + h3, ul + h4, ul + h5, ul + h6,
ol + h2, ol + h3, ol + h4, ol + h5, ol + h6,
.table-responsive + h2, .table-responsive + h3, .table-responsive + h4, .table-responsive + h5, .table-responsive + h6 {
  margin-top: 0;
}

// ////////////////////
// general links
// ////////////////////
a,
a:link,
a:visited {
  color: $gray;
  transition: color $default-transition-time;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: $purple;
    text-decoration: underline;
  }
}

.read-more-link {
  font-size: 15px;
}

// ////////////////////
// intro copy
// ////////////////////
@mixin intro-text-size {
  font-size: 17px;
  line-height: 1.35em;

  @include breakpoint(above, sm) {
    font-size: 19px;
  }

  @include breakpoint(above, xl) {
    font-size: 20px;
  }
}

.intro {
  @include intro-text-size;
}



// small body copy
.small {
  font-size: 14px;
  line-height: 21px;
}

// smaller grey error/date text body copy
.error-text,
.date-text,
.webinar-date {
  font-size: 14px;
  font-weight: bold;
  color: $gray;
  line-height: 21px;
}

.announcement-date {
  font-size: 14px;
  font-weight: bold;
  color: $gray;
  text-transform: uppercase;
}

.read-more-link {
  font-weight: bold;
  text-transform: uppercase;
}


// bulleted lists and their items
.main-content ul {
  margin: 0;
  padding: 0;
  list-style: none;
  color: $green;

  li {
    padding-left: 30px;
    padding-bottom: 20px;

    &::before {
      font-family: icomoon !important;
      font-style: normal;
      font-variant: normal;
      font-weight: normal;
      text-transform: none;
      content: '\e906';
      position: absolute;
      font-size: 34px;
      color: $blue;
      margin-left: -40px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}
