// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  clip: auto;
  height: auto;
  overflow: auto;
  position: static !important;
  width: auto;
}

@mixin clearfix {
  &::before,
  &::after {
    content: "";
    display: table;
  }
  &::after {
    clear: both;
  }
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

@mixin icomoon {
  font-family: icomoon !important;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-transform: none;
}

@mixin background-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
