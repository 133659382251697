/*
 * _buttons.scss
 */

// =============================================================================
// All Buttons
// -----------------------------------------------------------------------------

button {
	background-color: transparent;
	border: none;
	appearance: none;
}

.transform-uppercase{
	text-transform: uppercase;
}

.btn {
	display: inline-block;
    // max-height: 40px;
	@include responsive-margin(bottom, $scale: $spacing-p);
	padding: 1rem 3rem;
	background-color: $purple;
    background-image: linear-gradient(90deg, $purple 50%, darken($purple-light, 16%) 0);
    background-position: 0;
    background-size: 200% 100%;
    border: none;
    border-radius: 30px;
    font-family: $font-secondary-bold;
    font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 120% !important;
	letter-spacing: 0.1em;
	text-transform: uppercase;

    text-decoration: none !important;
    text-align: center;
    color: $white;
    appearance: none;
	cursor: pointer;
	transition: all $default-transition-time ease-in-out !important;

	&.btn-small {
        // max-height: 36px;
		// padding: 8px 14px !important;
		padding: 8px 25px 6px !important;
        text-transform: uppercase !important;
        font-family: $font-primary;
        font-size: 12px !important;
        line-height: 1.2 !important;

        span[class^="icon-"] {
            display: inline-block;
            margin-right: 6px;
            font-size: 14px;
            transform: translateY(2px);
        }
	}

	&.btn-full-width {
		display: block;
        width: 100%;
	}

    &.button-disabled {
        background: $gray-light;
        border-color: $gray-light;
    }

	&:hover,
	&:focus {
	// color:$grey;
    background-position: -100%;
    box-shadow: none !important;
		outline: 0;
	}

    @include breakpoint(above, xl) {
        max-height: 48px;
        // padding: 13px 32px !important;
    }
}

.btn-link {
    background-color: transparent;
    background-image: none;
    border: none !important;
}


// =============================================================================
// Primary Button
// -----------------------------------------------------------------------------

.btn-primary {
	background-color: $purple;
    background-image: linear-gradient(90deg, $purple 50%, darken($purple, 16%) 0);
	border-color: transparent !important;
	color: $white !important;

	&:hover,
	&:focus {
		background-color: darken($pink, 16%);
		color: $white !important;
	}
}


// =============================================================================
// Secondary Button
// -----------------------------------------------------------------------------

.btn-secondary {
	background-color: $purple;
    background-image: linear-gradient(90deg, $purple 50%, darken($purple, 16%) 0);
	border-color: transparent !important;
	color: $white !important;

	&:hover,
	&:focus {
		background-color: darken($purple, 16%);
		color: $white !important;
	}
}


// =============================================================================
// Outline Button
// -----------------------------------------------------------------------------

.btn-outline {
	background-color: transparent;
  background-image: linear-gradient(90deg, transparent 50%, $purple 0);
  background-size: 202% 100%;
	border: 1px solid $gray !important;
	color: $gray !important;

	&:hover,
	&:focus {
		// background-color: $purple;
    background-position: -99%;
		border-color: $purple !important;
		color: $white !important;
	}
}


///////////////////
// MSPL Activate //
///////////////////
a.mspl-activate-btn{
	color:$white !important;
	padding: 10px 0 !important;

	// &:hover,
	// &:focus {
	// 	color: $grey !important;
	// }

}