/*
 * _archives.scss
 */

.archive-header {
    @include responsive-margin(top, -1);
    @include responsive-padding(y);
    background-color: $purple;
    text-align: center;

    h1 {
        color: $white;
    }
}

.archive-navigation-container {
    height: 100%;
    @include responsive-padding(y);
    background-color: $purple-light;
}

.archive-navigation {
    @include responsive-margin(top);

    .archive-navigation-heading {
        @include responsive-padding(x);
    }

    .archive-navigation-list {
        list-style-type: none;
        margin-bottom: 0;
        padding-left: 0;

        li {
            padding: 12px 0;

            a {
                position: relative;
                display: block;
                @include responsive-padding(x);
                text-decoration: none !important;
                font-family: $font-secondary-bold;
                font-size: 14px;
                color: $gray;

                &.current {
                    color: $purple;

                    @include breakpoint(above, lg) {
                        &::after {
                            content: '\e91d';
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-family: icomoon;
                            font-size: 72px;
                            font-family: $icon-font-family;
                            color: $white;
                        }
                    }
                }
            }

            &:first-of-type {
                padding-top: 0;
            }
        }
    }

    .archive-navigation-form {
        @include responsive-margin(x);

        select {
            padding: 8px 20px;
            width: 100%;
            border: none;
            border-radius: 24px;
            font-size: 14px;
            color: $gray;
        }
    }

    .archive-navigation-accordion {
        @include responsive-margin(x);

        .accordion-item {
            border-top: 1px solid $purple-medium;

            .accordion-item--heading {
                padding: 16px 46px 16px 0;
                background-color: transparent;

                a {
                    color: $gray;

                    &:hover,
                    &:focus {
                        color: $purple;
                    }
                }

                &::after {
                    background-color: $gray;
                }

                &:hover::after {
                    background-color: $purple;
                }
            }

            .accordion-item--content {
                overflow: visible;
            }

            &.active .accordion-item--heading,
            &:hover .accordion-item--heading {

                a {
                    color: $purple;
                }

                &::after {
                    background-color: $purple;
                }
            }

            &:last-of-type {
                border-bottom: 1px solid $purple-medium;
            }
        }

        .archive-navigation-list {

            li {

                a {
                    padding: 0;

                    &.current::after {
                        right: 0;

                        @each $key, $value in $grid-breakpoints {
                            @include breakpoint(above, $key) {
                                right: -( #{map-get($spacing-horizontal, $key)} );
                            }
                        }
                    }

                    &:hover,
                    &:focus {
                        color: $purple;
                    }
                }

                &:first-of-type {
                    padding-top: 16px;
                }
            }
        }
    }

    @include breakpoint(above, lg) {
        margin-top: 0;
    }
}

.archive-navigation-toggle {
    @include responsive-margin(x);
    padding: 0;
    color: $gray;

    .archive-navigation-toggle-text::before {
        content: 'Hide ';
        display: inline-block;
        margin-right: 4px;
    }

    span[class^="icon-"] {
        display: inline-block;
        margin-left: 2px;
        transform: rotate(-180deg) translateY(1px);
        transition: transform $default-transition-time;
    }

    &.collapsed {

        .archive-navigation-toggle-text::before {
            content: 'Show ';
        }

        span[class^="icon-"] {
            transform: rotate(0deg) translateY(1px);
        }
    }
}

.archive-content {
    @include responsive-padding(all);
    @include responsive-padding(bottom, 2);
}

