@charset 'utf-8';

/// Hides an element visually while still allowing the content to be accessible
/// to assistive technology, e.g. screen readers. Passing `unhide` will reverse
/// the affects of the hiding, which is handy for showing the element on focus,
/// for example.
///
/// @group Layout
///
/// @link http://goo.gl/Vf1TGn
/// @source https://github.com/thoughtbot/bourbon/blob/04ac693bda30294ecb359aa97c43a3ee1b97c2a7/core/bourbon/library/_hide-visually.scss
///
/// ----------------------------------------------------------------------------
///
/// @example scss
///   .element {
///     @include hide-visually;
///
///     &:active,
///     &:focus {
///       @include unhide-visually;
///     }
///   }
///
/// @example css
///   .element {
///     border: 0;
///     clip: rect(1px, 1px, 1px, 1px);
///     clip-path: circle(1% at 1% 1%);
///     height: 1px;
///     overflow: hidden;
///     padding: 0;
///     position: absolute;
///     width: 1px;
///   }
///
///   .hide-visually:active,
///   .hide-visually:focus {
///     clip: auto;
///     clip-path: none;
///     height: auto;
///     overflow: visible;
///     position: static;
///     width: auto;
///   }

@mixin hide-visually {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: circle(1% at 1% 1%);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


/// Opposite of `hide-visually` mixin
///
/// The original Bourbon `hide-visually` mixin uses an awkward string-based
/// toggle to unhide elements. Splitting unhiding into a separate mixin seems
/// to be a bit clearer.
///
/// @group Layout

@mixin unhide-visually {
  clip: auto;
  clip-path: none;
  height: auto;
  overflow: visible;
  position: static;
  width: auto;
}
