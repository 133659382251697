/*
 * _columns.scss
 */

.wp-block-columns {

    &.four-col-grid-row {

        .wp-block-column {
            flex: 50%;

            @include breakpoint(above, md) {
                flex: 25%;
            }
        }
    }
}
