/*
 * _blockquote.scss
 */

.wp-block-quote {
	padding-left: 20px;
	border-left: 4px solid $gray-light;
	font-size: 1.5rem;

	cite {
		font-style: italic;
		font-size: 1rem;
		color: $gray-light;
	}
}
