#app.checkout-layout {
    padding-top: 0;
    background-color: $purple-light;

    .page-header {
        margin-top: 0;

        img {
          width: auto;
        }
    }

    .checkout-content {

        .sign-in-prompt {

            a {
                color: $blue;

                &:hover,
                &:focus {
                    color: $purple;
                }
            }
        }

        .checkout-steps {
            @include responsive-padding(all);

            .checkout-module {
                margin: 0 0 40px;
                border-radius: 8px;
                box-shadow: 0 -1px 2px rgba(0,0,0,0.025),
                            0 -2px 4px rgba(0,0,0,0.025),
                            0 1px 2px rgba(0,0,0,0.025),
                            0 2px 4px rgba(0,0,0,0.025),
                            0 4px 8px rgba(0,0,0,0.025),
                            0 8px 16px rgba(0,0,0,0.025),
                            0 16px 32px rgba(0,0,0,0.025);
                overflow: hidden;

                .module-heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @include responsive-padding(x, 0.5);
                    @include responsive-padding(y, 0.33333);
                    background-color: $purple;
                    color: $white;

                    h2,
                    p {
                        color: $white;
                        padding: 0;
                        margin: 0;
                    }

                    span.icon-check {
                        display: inline-block;
                        width: 32px;
                        height: 32px;
                        margin-left: 20px;
                        background-color: $blue;
                        border-radius: 50%;
                        font-size: 11px;
                        line-height: 32px;
                        text-align: center;
                    }

                    &.collapsed-heading {
                        background-color: $purple-medium;
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }
                }

                .module-inner-content {
                    @include responsive-padding(all, $scale: $spacing-card);
                    background-color: $white;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;

                    tr {
                        &.has-error {
                           background-color: #EB394F;
                        }
                    }

                   .StripeElement{
                        // background:blue;
                        &.StripeElement--empty{
                            // background:grey;
                        }
                    }
                }
            }

            table.shipping-methods-table {
                border-bottom: 1px solid $gray-light;

                .custom-control .custom-control-label,
                .shipping_description,
                .shipping_price {
                    color: $purple-medium;
                }

                .shipping_description,
                .shipping_price {
                    margin-left: 28px;

                    @include breakpoint(above, sm) {
                        margin-left: 0;
                    }
                }

                .shipping_price {
                    font-family: $font-secondary-bold;
                }

                tr {
                    border-top: 1px solid $gray-light;

                    &.active {

                        .custom-control .custom-control-label,
                        .shipping_price {
                            color: $purple;
                        }

                        .shipping_description {
                            color: $gray;
                        }
                    }

                    td:first-of-type {
                        padding-top: 0.75rem;
                    }

                    td:last-of-type {
                        padding-bottom: 0.75rem;
                    }
                }

                td {
                    display: block;
                    padding: 0;
                    border-color: transparent;

                    @include breakpoint(above, sm) {
                        display: table-cell;
                        vertical-align: middle;
                        padding: 0.75rem 0;
                        border-color: $gray-light;
                    }
                }
            }

            table.order-review-table {

                border-bottom: 1px solid $gray-light;
                font-family: $font-secondary-bold;

                // * {
                //     color: $purple-medium;
                // }

                th,
                td {
                    vertical-align: middle;
                    border-color: $gray-light;
                }

                tr:first-of-type th,
                tr:first-of-type td {
                    border-top: 0;
                }
            }

            .card-same-as-shipping {
                @include responsive-padding(all, 0.5);
                background-color: $gray-lighter;
                border-radius: 8px;
            }

            .terms-conditions-agreement {
                font-family: $font-secondary-bold;

                a {
                    color: $blue;

                    &:hover,
                    &:focus {
                        color: $purple;
                    }
                }
            }
        }

        .order-summary {
            max-width: 100%;
            @include responsive-padding(all);


            .order-summary-toggle {

                span[class^="icon-"] {
                    display: inline-block;
                    margin-left: 2px;
                    transform: rotate(-180deg) translateY(1px);
                    transition: transform $default-transition-time;
                }

                &.collapsed {

                    span[class^="icon-"] {
                        transform: rotate(0deg) translateY(1px);
                    }
                }
            }

            .item-thumbnail {
                position: relative;

                img {
                    width: 84px;
                    height: auto;
                }

                .counter {
                    position: absolute;
                    top: -10px;
                    right: 0px;
                    display: block;
                    min-width: 20px;
                    width: auto;
                    height: 20px;
                    padding: 0 5px;
                    background-color: $blue;
                    border-radius: 10px;
                    text-align: center;
                    font-size: 11px;
                    font-weight: normal;
                    line-height: 20px;
                    color: $white;
                }
            }

            .item-price {
                font-family: $font-secondary-bold;
            }

            .coupon-form {

                .form-group {
                    width: 100%;
                    margin-right: 24px;
                }
            }

            .order-summary-subtotal {
                font-family: $font-secondary-bold;
            }
        }
    }

    .tooltip-info {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 4px;
        background-color: $purple;
        border-radius: 50%;
        text-align: center;
        font-size: 11px;
        line-height: 20px;
        color: $white;
        cursor: help;
        transform: translateY(-1px);
    }
}

.Input--empty{
    border: 2px solid black !important;
}

.checkout-errors{
    color:$red;
    margin:15px 0;
}
