/*
 * _accordion.scss
 */

.accordion-block {}

.accordion-item {

	.accordion-item--content {
    display: none;
  }
}

