@charset 'utf-8';

/// Surrounds the `@content` in a media query for the provided breakpoint
///
/// @group Layout
/// @see {variable} breakpoints
///
/// ----------------------------------------------------------------------------
///
/// @param {string} $range
///   Must be `above`, `below`, `only`, or `between`
///
/// @param {string} $breakpoint
///   Name of the reference breakpoint for defining `$range`
///
/// @param {string} $breakpoint-end [null]
///   If using `between`, defines the end of the range
///
///
/// ----------------------------------------------------------------------------
///
/// @example scss - Above
///   div {
///     color: red;
///
///     @include breakpoint(above, small) {
///       color: blue;
///     }
///   }
///
/// @example css
///    div {
///      color: red;
///    }
///
///    @media only screen and (min-width: 600px) {
///      div {
///        color: blue;
///      }
///    }
///
/// ----------------------------------------------------------------------------
///
/// @example scss - Between
///   div {
///     color: red;
///
///     @include breakpoint(between, small, large) {
///       color: blue;
///     }
///   }
///
/// @example css
///    div {
///      color: red;
///    }
///
///    @media only screen and (min-width: 600px) and (max-width: 999px) {
///      div {
///        color: blue;
///      }
///    }

@mixin breakpoint(
  $range,
  $breakpoint,
  $breakpoint-end: null
) {
  $valid-ranges: ('above', 'below', 'only', 'between');

  @if not index($valid-ranges, $range) {
    @error 'Unknown breakpoint range: #{$range}. Must be one of: #{$valid-ranges}';
  }

  $query: null;

  @if $range == 'above' {
    $query: _breakpoint-above($breakpoint);
  } @else if $range == 'below' {
    $query: _breakpoint-below($breakpoint);
  } @else if $range == 'only' {
    $next-breakpoint: _get-next-breakpoint($breakpoint);

    @if ($next-breakpoint) {
      $query: _breakpoint-between($breakpoint, $next-breakpoint);
    } @else {
      $query: _breakpoint-above($breakpoint);
    }
  } @else if $range == 'between' {
    @if not $breakpoint-end {
      @error 'Must provide an ending breakpoint when using the `between` range';
    }

    $query: _breakpoint-between($breakpoint, $breakpoint-end);
  }

  @if ($query) {
    @media #{$query} {
      @content;
    }
  } @else {
    @content;
  }
}
