/*
 * _account.scss
 */

body.login,
body.register,
body.email-verify,
body[class^="password-reset"],
body[class^="my-account"] {

    #app {
        background-color: $purple-light;
        padding-top: 0;
    }

    .create-account-link {
        color: $purple;
    }
}

table.table.order-table {
    color: $gray;

    td {
        vertical-align: middle;
    }

    tr {
        th,
        td {
            &:first-of-type {
                padding-left: 0;
            }

            &:last-of-type {
                padding-right: 0;
            }
        }
    }

    thead th {
        border: none;
    }
}

ul.order-details-product-list {
    list-style-type: none;
    padding-left: 0;

    li {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid $purple-light;

        div.product-description {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: space-between;
        }

        img {
            width: 20%;
        }

        p > span {
            margin-bottom: 6px !important;
        }
    }
}
