#app.thankyou-layout {
    padding-top: 0;
    background-color: $blue-light;

    .page-header {
        margin-top: 0;
        @include responsive-padding(y, 2);
        color: $white;

        img {
          width: auto;
        }

        .order-number-container {
            display: inline-block;
            margin: 0 auto 24px;
            padding: 12px 28px;
            background-color: $white;
            border-radius: 8px;
            text-transform: uppercase;
            text-align: center;
            font-family: $font-secondary-bold;
            font-size: 20px;
            color: $blue;
        }

    }
}
