/*
 * _pages.scss
 */

.page-header {
    @include responsive-margin(top, -1);
    @include responsive-padding(y);
    background-color: $purple;
    text-align: center;

    h1 {
        color: $white;
    }
}

.page-content {
    // @include responsive-padding(top);
}

body.home {
    .page-content {
        padding-top: 0;
        padding-bottom: 0;
    }
}



#mspl{
    .page-header,
    .archive-header {
        // @include responsive-margin(top, -1);
        // @include responsive-padding(y);
        background-color: $purple;
        text-align: center;
        margin-top:0;

        h1 {
            color: $white;
        }
    }


}