/* MARKETING SITE CSS
* Please note  Tequila use of row and container has been modified to altos-container and altos-row
* --cam
*/
// Fonts
//@import url('https://fonts.googleapis.com/css?family=Nunito');

//tequila
@import '~@altos/tequila/lib/tequila';
@import '~@altos/tequila/lib/lime';

// // Variables
@import 'store/config'; //overwrite bootstrap before loading

// //altos parent
@import 'altos_parent/styles';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

// //altos child
@import 'store/styles';

