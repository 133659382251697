/*
 * _compare-kits.scss
 */

.compare-kits-block {}

.compare-kits-grid {
    display: flex;
    flex-direction: column; /* turn children ul elements into stacked rows */
}

.compare-kits-grid ul.compare-kits-row {
    list-style: none;
    display: flex; /* turn children li elements into flex children */
    // flex: 1;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0;
    padding: 0;

    // &:not(.compare-kits-header-row) {

    // }

    &:nth-of-type(even) {
        background-color: $blue-light;
    }
}

.compare-kits-grid ul.compare-kits-row > li {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @include responsive-padding(all, $scale: $spacing-kit-grid-cells);
    text-align: center;

    &.legend {
        flex: 100%;
        justify-content: flex-start;
        padding-bottom: 0;
        text-align: left;

        .legend-inner {
            position: relative;
            width: 100%;
        }

        .legend-description {
            display: none;

            @include breakpoint(above, md) {
                display: block;
            }
        }

        .legend-expand {
            position: absolute;
            top: -4px;
            right: 0;
            color: $gray;

            span:not([class]) {
                text-decoration: underline;
            }

            span[class^="icon-"] {
                display: inline-block;
                transform: translateY(2px);
                font-size: 14px;
            }

            &:hover,
            &:focus {
                color: $purple;
            }

            @include breakpoint(above, md) {
                display: none;
            }
        }

        p {
            margin-bottom: 0;
        }

        &.active {

            .legend-expand span[class^="icon-"] {
                transform: translateY(0) rotate(180deg);
            }
        }

        @include breakpoint(above, md) {
            flex: 1;
            @include responsive-padding(all, $scale: $spacing-kit-grid-cells);
        }

        @include breakpoint(above, lg) {
            flex: 1.5;
        }
    }

    @include breakpoint(above, md) {
        &.featured {
            box-shadow: -1px 0 0px rgba(0,0,0,0.015),
                        -2px 0 1px rgba(0,0,0,0.015),
                        -4px 0 2px rgba(0,0,0,0.015),
                        1px 0 0px rgba(0,0,0,0.015),
                        2px 0 1px rgba(0,0,0,0.015),
                        4px 0 2px rgba(0,0,0,0.015);
        }
    }

    img {
        margin: 0 auto;

        &.icon-checkmark {
            width: 40px;

            @include breakpoint(above, sm) {
                width: 56px;
            }
        }
    }

    span[class^="icon-close"] {
        font-size: 36px;
        color: $gray-light;
    }
}

.compare-kits-grid ul.compare-kits-header-row li {
    align-items: flex-start;

    &.legend {
        display: flex;
        align-items: center;
    }

    @include breakpoint(above, md) {

        &.featured {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            box-shadow: -1px 0 0px rgba(0,0,0,0.015),
                        -2px 0 1px rgba(0,0,0,0.015),
                        -4px 0 2px rgba(0,0,0,0.015),
                        1px 0 0px rgba(0,0,0,0.015),
                        2px 0 1px rgba(0,0,0,0.015),
                        4px 0 2px rgba(0,0,0,0.015),
                        0 -1px 0px rgba(0,0,0,0.015),
                        0 -2px 1px rgba(0,0,0,0.015),
                        0 -4px 2px rgba(0,0,0,0.015);
        }
    }

    img {
        @include responsive-margin(bottom, $scale: $spacing-p);
    }
}

.compare-kits-grid ul.compare-kits-stool-type-row li {

    .stool-type-key {
        @include responsive-margin(y, $scale: $spacing-p);
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 8px;

            @include breakpoint(above, lg) {
                margin-bottom: 0;
            }
        }

        img {
            margin-right: 4px;
        }

        @include breakpoint(above, lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    img {
        display: inline-block;
        width: 23px;
        margin: 0 5px;

        @include breakpoint(above, sm) {
            width: 32px;
        }
    }
}

.compare-kits-grid ul.compare-kits-price-row {
    align-items: flex-start !important;

    @include breakpoint(above, md) {

        li.featured {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            box-shadow: -1px 0 0px rgba(0,0,0,0.015),
                        -2px 0 1px rgba(0,0,0,0.015),
                        -4px 0 2px rgba(0,0,0,0.015),
                        1px 0 0px rgba(0,0,0,0.015),
                        2px 0 1px rgba(0,0,0,0.015),
                        4px 0 2px rgba(0,0,0,0.015),
                        0 1px 0px rgba(0,0,0,0.015),
                        0 2px 1px rgba(0,0,0,0.015),
                        0 4px 2px rgba(0,0,0,0.015);
        }
    }
}
