/*
 * _utility.scss
 */

 .img-hover {
  overflow: hidden;

  img {
    display: block;
    transform-origin: center;
    transition: transform .75s;
    will-change: transform;
  }

  &:hover,
  &:focus {
    img {
      transform: scale(1.2);
    }
  }
}

.visually-hidden {
  @include element-invisible;
}

.visually-hidden-off {
  @include element-invisible-off;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-none {
  display: none;
}

.no-margin {
  margin: 0;
}

.no-margin--top {
  margin-top: 0;
}

.no-margin--right {
  margin-right: 0;
}

.no-margin--bottom {
  margin-bottom: 0;
}

.no-margin--left {
  margin-left: 0;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: 700;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

