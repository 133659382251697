

//-------------------------------------------
// Cart
//-------------------------------------------
table.cart-items {
  @include responsive-margin(y, $scale: $spacing-default);

  width: 100%;
  border-collapse: collapse;
  font-family: $font-primary;

  th {
    display: none; // hide all TH's below 800px

    @include breakpoint(above, lg) {
      display: table-cell;
      @include h6;
      color: $gray;
      border-bottom: 1px solid $gray-light;
      padding-bottom: 10px;

      &.first {
        text-align: left;
      }

      &.last {
        text-align: right;
      }
    }
  }

  tbody {
    // border-top: 1px solid $gray-light;
    border-bottom: 1px solid $gray-light;
  }

  tr.cart-item-row {
    border-top: 1px solid $gray-light;

    td {
      padding-top: 10px;
      padding-bottom: 10px;

      @include breakpoint(above, lg) {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }

    td.thumbnail {
      padding-right: 20px;

      img {
        display: block;
        max-width: 98px;
        min-width: 98px;
        width: 100%;
        height: auto;

        @include breakpoint(above, lg) {
          max-width: 128px;
        }
      }
    }

    td.details {
      display: table-cell;

      > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        .cart-item-thumbnail,
        .cart-item-description,
        .cart-item-price,
        .cart-item-qty {
          margin-right: 20px;
        }

        .cart-item-description,
        .cart-item-price {
          width: 100%;

          @include breakpoint(above, lg) {
            width: auto;
          }
        }

        h4 {
          margin: 0;
        }

        .pulled-element {
          margin-left: auto;
          margin-right: 8%;
        }
      }
    }

    td.total {
      display: table-cell;
      padding-left: 20px;
      font-size: 20px;
      font-weight: bold;
      text-align: right;
    }
  }
}

// Notes font
.notes {
  color: $gray;
  font-family: $font-primary;
}


// Special behavior for the cart buttons -- double buttons at bottom of cart page
.cart-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @include breakpoint(above, md) {
    justify-content: flex-end;
  }

  a {
    width: 100%;
    text-align: center;

    @include breakpoint(above, sm) {
      width: auto;
    }
  }
}


// Special behavior for the cart buttons -- single button at top of cart page
.cart-buttons-single {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;

  @include breakpoint(above, lg) {
    justify-content: flex-end;
  }

  a {
    width: 100%;
    text-align: center;

    @include breakpoint(above, sm) {
      width: auto;
    }
  }
}

