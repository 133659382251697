/*
 * _columns.scss
 */

.wp-block-columns.alignfull {
    @include responsive-padding(x);
}

.wp-block-columns {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 -15px;

    &.are-vertically-aligned-bottom {
        align-items: flex-end;
    }

    &.are-vertically-aligned-center {
        align-items: center;
    }

    &.row-reverse {
        flex-direction: column-reverse;

        @include breakpoint(above, sm) {
            flex-direction: row;
        }
    }

    .wp-block-column {
        flex-basis: 100%;
        @include responsive-margin(top);
        padding: 0 15px;

        &:first-of-type {
            margin-top: 0;
        }
    }

    @include breakpoint(above, md) {
        flex-wrap: nowrap;

        .wp-block-column {
            // flex: 1;
            margin-top: 0;
            margin-bottom: 0;

            .wp-block-group.card {
                height: 100%;
            }
        }

        // width: 100%;
        // margin-right: 0;
        // @include responsive-margin(top, 0.5);

        // &:first-of-type {
        //  margin-top: 0;
        // }

        // &:empty {
        //  display: none;
        // }
    }
}

// .wp-block-columns.has-2-columns {

//     .wp-block-column {

//         @each $key, $value in $grid-breakpoints {
//         @include breakpoint(above, $key) {
//                 width: calc(50% - #{map-get($grid-gutter-widths, $key)});

//               &:nth-child(2n) {
//                   margin-right: 0;
//                 }
//         }
//       }

//         @include breakpoint(below, md) {
//             width: 100%;
//             margin-right: 0;
//             @include responsive-margin(top, 0.5);

//             &:first-of-type {
//                 margin-top: 0;
//             }

//             &:empty {
//                 display: none;
//             }
//         }
//     }

//     @include breakpoint(above, $two-column-breakpoint) {
//         flex-wrap: nowrap;

//         .wp-block-column {
//             margin-top: 0;
//             margin-bottom: 0;
//         }
//     }
// }

// .wp-block-columns.has-3-columns {

//     .wp-block-column {

//         @each $key, $value in $grid-breakpoints {
//         @include breakpoint(above, $key) {
//                 width: calc(33.3333% - #{map-get($grid-gutter-widths, $key)});

//               &:nth-child(3n) {
//                   margin-right: 0;
//                 }
//         }
//       }

//     @include breakpoint(below, md) {
//         width: 100%;
//             margin-right: 0;
//             @include responsive-margin(top, 0.5);

//             &:first-of-type {
//                 margin-top: 0;
//             }

//             &:empty {
//                 display: none;
//             }
//         }
//     }

//     @include breakpoint(above, $three-column-breakpoint) {
//         flex-wrap: nowrap;

//         .wp-block-column {
//             margin-top: 0;
//             margin-bottom: 0;
//         }
//     }
// }

