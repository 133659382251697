/*
 * _hero.scss
 */

.hero-block {
    position: relative;
    // height: 0;
    // padding-top: 56.25%;
    // @include responsive-padding(all);
    background-color: $gray-light;
    overflow: hidden;
    //remove button and change bg color for mspl2.6
    #mspl &{
        background-color: $white;
        .wp-block-button{
            display: none;
        }
        .wp-block-columns{
            display: block;
        }
        .hero-block--text{
            align-items: baseline;
            margin-top: 3em;
            max-width:700px;
            margin:0 auto;
            text-align: center;
        }
    }
    .hero-block--image {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        @include bg-cover(80% 50%);

        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: $blue-light;
            opacity: 0.70;

            @include breakpoint(above, md) {
                display: none;
            }
        }

        @include breakpoint(above, md) {
            @include bg-cover(64% 50%);
        }
    }

    .hero-block--text {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-height: auto;
        /*padding: 32px 48px;*/
        /*padding-left: 0;*/
        @include responsive-padding(y);
        // text-align: center;
        color: $gray;

        h1,
        h3 {
            position: relative;
            z-index: 1;
        }

        h1 {
            margin-bottom: 18px;
            color: $purple;

            // @include breakpoint(above, lg) {
            //     margin-bottom: 18px;
            // }
        }

        h3 {
            color: $white;
        }

        @include breakpoint(above, md) {
            min-height: 530px;
        }

        @include breakpoint(above,  xl) {
            min-height: 700px;
        }
    }
}
