/*
 * _config.scss
 */

// =============================================================================
// General
// -----------------------------------------------------------------------------

@import 'config/general.scss';


// =============================================================================
// Colors
// -----------------------------------------------------------------------------

// Body
$body-bg: #ffffff;

// Typography
// $font-family-sans-serif: 'Nunito', sans-serif;
// $font-size-base: 0.9rem;
// $line-height-base: 1.6;

// Colors
$white: #ffffff;
$gray: #68757A;
$gray-light: #C8CDCF;
$gray-lighter: #F2F3F3;
$grey: $gray;
$grey-light: $gray-light;
$grey-lighter: $gray-lighter;

$purple: #512179;
$purple-medium: #B5A5BD;
$purple-light: #D6CEE0;
$subtle-purple: #9B8BB4;
// #mspl
$nav-purple: #EAE6EF;
//$purple-light: #9B8BB5; //dashboard edits?

//$blue: #00A0E3;
$blue: #00A9E0;
$blue-medium: #C2EBFD;
$blue-light: #F0FAFF;
$newblue: #C1E6FF;

$pink: #EB3986;
$pink-medium: #FFBBDA;
$pink-light: #FFEEF6;
$dark-pink: #C95C74;
$subtle-pink: #E6BCCA;

$orange: #EB7E46;
$subtle-orange: #F6D0BB;

$green: #6CB47A;
$red: #EB5757;

$colors: (
    'white': $white,
    'gray': $gray,
    'gray-light': $gray-light,
    'gray-lighter': $gray-lighter,
    'purple': $purple,
    'purple-medium': $purple-medium,
    'purple-light': $purple-light,
    'subtle-purple': $subtle-purple,
    'blue': $blue,
    'blue-medium': $blue-medium,
    'blue-light': $blue-light,
    'pink': $pink,
    'pink-medium': $pink-medium,
    'pink-light': $pink-light,
    'dark-pink': $dark-pink,
    'subtle-pink': $subtle-pink,
    'orange': $orange,
    'subtle-orange': $subtle-orange,
);

// Merge the two color maps
// $colors: map-merge($grayscale-colors, $brand-colors);

// Class names for each color
@each $color-name, $color-value in $colors{
  .text-#{$color-name} {
    color: $color-value !important;
  }
  .bg-#{$color-name} {
    background-color: $color-value !important;
  }
}

// Easier syntax for retrieving colors
// @function color($color-name){
//   @return map-get($colors, $color-name);
// }


// =============================================================================
// Container
// -----------------------------------------------------------------------------

$site-max-width: 7681px;

$container-widths: (
    xs: 280px,
    sm: 516px,
    md: 708px,
    lg: 912px,
    xl: 1020px,
    xxl: 1200px
);


// =============================================================================
// Breakpoints
// -----------------------------------------------------------------------------

@import 'config/spacing.scss';

$breakpoints: ( //tequila
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px
);

// this matches the parent theme
// needed here too, but don't change it
// $menu-breakpoint: 'lg';

// this is used to get the key for largest breakpoint
$largest-breakpoint: '';

@each $key, $value in $breakpoints {
  $largest-breakpoint: $key;
}


// =============================================================================
// Grid
// -----------------------------------------------------------------------------

$grid-breakpoints: $breakpoints; // sets bootstrap defaults
// $grid-breakpoints: (
//   xs: 0,
//   sm: 576px,
//   md: 768px,
//   lg: 992px,
//   xl: 1200px
// ) !default;

$grid-gutter-widths: (
  'xs': 20px,
  'sm': 30px,
  'md': 40px,
  'lg': 40px,
  'xl': 70px,
  'xxl': 100px
);

$grid-columns: 12;
$grid-gutter-width: 30px;

$two-column-breakpoint: 'md';
$three-column-breakpoint: 'lg';


// =============================================================================
// Sticky Header
// -----------------------------------------------------------------------------

// used when there is a sticky menu
$sticky-menu-height: 70px;

// used if the top bar stays on mobile and there is a sticky menu
$sticky-menu-offset: 20px;


// =============================================================================
// Typography
// -----------------------------------------------------------------------------

@import 'config/font.scss';

// Icon font
$icon-font-family: icomoon;

//Alerts
$alert-padding-y: 2.5rem;


// =============================================================================
// Buttons
// -----------------------------------------------------------------------------


// =============================================================================
// Animations
// -----------------------------------------------------------------------------

