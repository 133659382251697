/*
 * _typography.scss
 */

// =============================================================================
// Fonts
// -----------------------------------------------------------------------------


// =============================================================================
// Body
// -----------------------------------------------------------------------------

body {
  font-family: $font-primary;
  font-size: 18px;
  line-height: 150%;
  color: $purple;
  // font-display: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // @include breakpoint(above, xl) {
  //   font-size: 24px;
  // }
}


// =============================================================================
// Headings
// -----------------------------------------------------------------------------

// heading one
@mixin h1 {
  @include responsive-margin(bottom, $scale: $spacing-h1);
  color: $purple;
  font-family: $heading-font-family;
  // font-size: 30px;
  font-size: 50px;
  font-weight: 700;
  line-height: 130%;

  // @include breakpoint(above, sm) {
  //   font-size: 40px;
  // }

  // @include breakpoint(above, xl) {
  //   font-size: 50px;
  // }

  a,
  a:link,
  a:visited {
    color: inherit;
  }

  &.no-margin {
    margin: 0;
  }
}

h1,
.h1 {
  @include h1;
}

// heading two
@mixin h2 {
  @include responsive-margin(bottom, $scale: $spacing-h2);
  color: $purple;
  font-family: $heading-font-family;
  // font-size: 24px;
  font-size: 36px;
  font-weight: 700;
  line-height: 130%;

  // @include breakpoint(above, sm) {
  //   font-size: 30px;
  // }

  // @include breakpoint(above, lg) {
  //   font-size: 40px;
  // }

  // @include breakpoint(above, xl) {
  //   font-size: 50px;
  // }

  a,
  a:link,
  a:visited {
    color: inherit;
  }
}

h2,
.h2 {
  @include h2;
}

// heading three

@mixin h3 {
  @include responsive-margin(bottom, $scale: $spacing-h3);
  color: $purple;
  font-family: $heading-font-family;
  font-weight: 700;
  // font-size: 18px;
  font-size: 32px;
  line-height: 130%;

  // @include breakpoint(above, sm) {
  //   font-size: 20px;
  // }

  // @include breakpoint(above, xl) {
  //   font-size: 24px;
  // }

  a,
  a:link,
  a:visited {
    color: inherit;
  }
}

h3,
.h3 {
  @include h3;
}

// heading four
@mixin h4 {
  @include responsive-margin(bottom, $scale: $spacing-h4);
  color: $purple;
  font-family: $heading-font-family;
  // font-size: 18px;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;

  // @include breakpoint(above, lg) {
  //   font-size: 20px;
  // }

  a,
  a:link,
  a:visited {
    color: inherit;
  }
}

h4,
.h4 {
  @include h4;
}

@mixin h5 {
  @include responsive-margin(bottom, $scale: $spacing-h5);
  color: $gray;
  font-family: $heading-font-family;
  font-size: 14px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;

  a,
  a:link,
  a:visited {
    color: inherit;
    text-decoration: none;
  }
}

h5,
.h5 {
  @include h5;
}

@mixin h6 {
  @include responsive-margin(bottom, $scale: $spacing-h6);
  color: $gray;
  font-family: $heading-font-family;
  font-size: 10px;
  font-weight: 700;
  line-height: 100%;
  text-transform: uppercase;

  a,
  a:link,
  a:visited {
    color: inherit;
  }
}

h6,
.h6 {
  @include h6;
}


// =============================================================================
// Text Blocks
// -----------------------------------------------------------------------------

p {
  @include responsive-margin(bottom, $scale: $spacing-p);
  line-height: 150%;
  font-size: 18px;

  // @include breakpoint(above, xl) {
  //   font-size: 24px;
  // }
}

.text-small,
.help-block,
figcaption {
  font-size: 14px;
  line-height: 150%;
}

.text-extra-small {
  font-size: 12px !important;
  line-height: 150%;
}

.text-large {
  font-size: 24px;
  line-height: 150%;

  // @include breakpoint(above, xl) {
  //   font-size: 20px;
  // }
}

.thin-container {
    max-width: 610px;
    margin-left: auto;
    margin-right: auto;
}

hr {
    border-color: $gray-light;
}

blockquote {
  @include responsive-margin(bottom, $scale: $spacing-p);
}

ul,
ol {
  @include responsive-margin(bottom, $scale: $spacing-p);
  padding-left: 1.25rem;
}

ul {
  list-style-type: disc;
  padding-left: 32px;

  &.styled-checkmark {
    list-style-type: none;
    margin-bottom: 32px;

    li {
      position: relative;
      margin-bottom: 10px;

      &::before {
        content: "\e90c";
        position: absolute;
        top: 0;
        left: -32px;
        font-family: $icon-font-family;
        font-size: 14px;
        color: $blue;
      }
    }
  }

  &.mspl-checkmark-list {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    list-style-type: none;
    font-size: 24px;
    line-height: 150%;

    li { 
      position: relative;
      margin-left: 75px;
      margin-bottom: 16px;

      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: -75px;
        display: inline-block;
        width: 34px;
        height: 32px;
        background-image: url('../images/store/vet-approved-white.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: contain;
        margin-right: 40px;
        transform: translateY(8px);
      }
    }
  }
}

ol {
  list-style-type: decimal;
}


// =============================================================================
// Text Links
// -----------------------------------------------------------------------------

a {
    text-decoration: underline;
    color: $gray;

    &:hover,
    &:focus {
        color: $purple;
    }
}

