/*
 * _group.scss
 */

.wp-block-group {

    &.card .wp-block-group__inner-container {
        @extend .card-body;
    }

    &.alignfull .wp-block-group__inner-container {
        @extend .container;
        // padding-left: 15px !important;
        // padding-right: 15px !important;
        @include responsive-padding(x);
    }
}
