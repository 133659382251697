/*
 * _product.scss
 */

.site-main .product {
    position: relative;

    h1 {
        color: $blue;

        &:first-line {
            color: $purple;
        }
    }

    .product-section {
        @include responsive-margin(y);
    }

    ul.stool-types {
        display: flex;
        padding-left: 0;
        font-family: $font-secondary-bold;
        font-size: 14px;
        color: $purple;

        li {
            width: 48%;
            list-style-type: none;

            img {
                display: inline-block;
                width: 26px;
                height: auto;
                margin-right: 1rem;
            }

            &.text-disabled {
                color: $gray-light;

                img {
                    filter: grayscale(100%);
                    opacity: 0.5;
                }
            }
        }
    }
}

.shop-bar {
    position: sticky;
    z-index: 1029; // navbar is 1030
    top: ($navbar-height - 1);
    // @include responsive-padding(y, $scale: $spacing-header);
    padding: 6px 0;
    background-color: $purple;
    color: $white;

    @include breakpoint(above, sm) {
        padding: 16px 0;
    }

    @include breakpoint(above, lg) {
        padding: 26px 0;
    }

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    p.price {
        margin-bottom: 0;

        * {
            color: $white !important;
        }

        span.product-price,
        span.product-note {
            display: block;
        }

        span.product-note {
            font-size: 12px;
        }

        @include breakpoint(above, md) {

            span.product-price,
            span.product-note {
                display: inline-block;
            }

            span.product-note {
                font-size: inherit;
            }
        }
    }

    .add-to-cart {
        display: flex;
        align-items: center;

        .qty-field .input-group label {
            color: $white;
        }

        button.btn-add-to-cart {
            margin-left: 10px;
            min-width: 80px;

            @include breakpoint(above, sm) {
                margin-left: 24px;
                min-width: 148px;
            }
        }
    }
}

.qty-field {
    .input-group {
        align-items: center;

        label {
            margin: 0 0.25rem 0;
            font-size: 12px;

            @include breakpoint(above, sm) {
                margin: 0 0.5rem 0;
                font-size: 14px;
            }

            @include breakpoint(above, lg) {
                display: none;
            }
        }

        .input-group-btn {
            width: 50px;
            height: 48px;
            background-color: $white;
            border: 1px solid $gray-light;
            text-align: center;
            font-size: 20px;
            line-height: 46px;
            transition: background-color $default-transition-time;

            &:first-of-type {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                border-right: none;
            }

            &:last-of-type {
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                border-left: none;
                line-height: 44px;

                span[class^="icon-"] {
                    font-size: 16px;
                }
            }

            span[class^="icon-"] {
                display: inline-block;
                width: 100%;
                color: $gray-light !important;
                transition: color $default-transition-time;
            }

            &:hover,
            &:focus {
                background-color: $gray-lighter;

                span[class^="icon-"] {
                    color: $gray !important;
                }
            }
        }

        input[type="text"] {
            // width: 50px;
            width: 58px;
            height: 42px;
            padding: 6px 8px;
            background-color: $white;
            border: 1px solid $gray-light !important;
            border-radius: 5px;
            text-align: center;
            font-size: 16px;
            font-family: $font-secondary-bold;
            color: $blue;
            appearance: none;

            &:hover,
            &:focus {
                border-color: $gray-light !important;
            }

            &:focus {
                color: $gray;
            }

            @include breakpoint(above, sm) {
                padding: 13px 16px;
                font-size: 20px;
            }

            @include breakpoint(above, lg) {
                width: 68px;
                height: 48px;
                border-radius: 0;
            }
        }
    }
}
// MSPL
#mspl{
    .msplapp{
        .mspl-shop-kit-category-buckets{
            a{
                display: block;
                padding:.5rem .1rem;
                color:$purple;
                text-decoration: none;
                transition:background .5s ease;
                &:hover{
                    background:$newblue;
                }
                &:focus{
                    background:$blue;
                }
            }
            img{
                margin:0 auto;
            }
            figcaption{
                color:$purple;
                display: block;
                margin:1em auto;
                font-weight: bold;
                text-align: center;
                font-size: 32px;
                line-height: 130%;
            }
            p{
                font-size: 24px;
            }
        }
    }

}

