/**
 * _utility.scss
 */

/*
Font Size
 */
@mixin font-size($size: 20px) {
  font-size: $size * 1;
  font-size: ($size / $body-font-size) * 1rem;
}

/*
Font Smoothing
 */
@mixin font-smoothing($value: on) {
  @if ($value == on) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/*
Aspect Ratio
 */
@mixin aspect-ratio($ratio: 56.25%) {
  position: relative;
  padding-top: $ratio;
  max-width: 100%;
  height: auto;
  overflow: hidden;

  > * {
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
  }
}

/*
Backgrounds
 */
@mixin bg-cover($position: 50% 50%) {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: $position;
}

/*
Vertical align
 */
@mixin vert-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
