/*
 * _forms.scss
 */

.form-group
.input-group {
    @include responsive-margin(bottom, $scale: $spacing-p);
}

input:not([type=button]):not([type=submit]),
select,
textarea,
.form-control {
	position: relative;
	// @include responsive-margin(bottom, $scale: $spacing-p);
	padding: 15px 20px;
	background-color: $white;
	border: 1px solid $gray-light;
    border-radius: 4px;
	box-sizing: border-box;
	font-family: $font-primary;
	font-size: 14px;
	line-height: 20px;
	color: $gray-light;
	transition: border-color $default-transition-time ease-in-out,
				color $default-transition-time ease-in-out;

	&:hover,
	&:focus,
	&:active  {
		border-color: $gray !important;
		outline: 0;
	}

	&:focus,
	&:active {
		color: $gray;
	}

    &.error,
    &.has-error,
    &.is-invalid {
        border: 1px solid $red;
    }
}

.has-error .form-control {
    border: 1px solid $red !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
}

select.form-control {
    padding: 0 20px;
    padding-right: 40px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../images/store/select_arrow.svg');
    background-repeat: no-repeat;
    background-position: right 20px center;
    background-size: 14px 8px;
}

select.form-control::-ms-expand {
    display: none;
}

// .form-control {
//     height: auto;
// }

label {
    font-family: $font-secondary-bold;
    font-size: 15px;
    line-height: 120%;
    color: $purple;
}

body.login input.custom-control-input[type="checkbox"] + label {
    font-family: $font-primary;
}

// This fades non-selected items in the checkbox/radio list
.custom-control.custom-control-highlight {

    .custom-control-label {
        color: $gray-light;
    }

    .custom-control-input:checked ~ .custom-control-label {
        color: $purple;
    }
}

input.custom-control-input {
    display: inherit;
    width: 0;
    height: 0;
    margin-bottom: 0 !important;
    padding: 0 !important;
    font-size: 0 !important;
    line-height: 0 !important;
}

.custom-control-label {
    vertical-align: middle;

    small {
        font-family: $font-primary;
    }
}

.custom-control-label::before {
    border-radius: 50% !important;
    border-color: $gray-light;
    background-color: $gray-lighter;
}

.custom-control-label:hover::before {
    border-color: $green;
}

.custom-control-input:checked ~ .custom-control-label::before {
    border-color: $green;
    background-color: $green;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik03LjU4MzcyIDAuMTEyNDE1QzcuMTg1OTMgLTAuMTE5MDkxIDYuNjc3NjIgMC4wMTgzMjAxIDYuNDQ4OTEgMC40MjAzNDVMMy4zMTA2MSA1LjkxNTk0TDEuNTYyNzkgMy44NDE5QzEuMjQ2MzMgMy40ODY2MyAwLjgyNTc2NyAzLjMwMjQ2IDAuNDI4NTYzIDMuNTMzOTdDMC4wMzA3NzAzIDMuNzY2MDUgLTAuMTMzNjA4IDQuMzI0MzcgMC4xMjM4MjEgNC42ODI1TDIuNTU2MDYgNy41Njg1N0MyLjczNzk3IDcuNzk4OTMgMi45NDU2IDcuOTM2OTggMy4xNjI2NiA3Ljk3NjM0TDMuMTY0NDIgNy45Nzk3OEwzLjE4NjY3IDcuOTgwOTNDMy4yMzU4MiA3Ljk4ODMzIDMuOTcwNTUgOC4xMjA2NCA0LjI4NyA3LjU2ODY0TDcuODg5MTkgMS4yNjEwMUM4LjExNzc2IDAuODU4Mjg2IDcuOTgxNTIgMC4zNDQ0OTUgNy41ODM3MiAwLjExMjQxNVoiIGZpbGw9IndoaXRlIi8+PC9zdmc+);
}

.custom-control-label::before,
.custom-control-label::after {
    top: 2px;
}


.wpcf7 {

    .error-message {
        color:red;
    }
    .wpcf7-response-output {
        p {
            font-weight: bold;
        }
    }
}

.success-message {
    min-height: 4rem;
    text-align: center;
    color: $purple-light;
    font-size: 2em;
}

.invalid-feedback {
    font-family: $font-primary;
    font-size: 13px;
    color: $red;
}

.invalid-icon {
    float: right;
    font-size: 15px;
    color: $red;
    transform: translateY(4px);
}

.help-block {
    color:$red;
}
