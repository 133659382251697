@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {

    .body,
    html {
        // color: green;
    } /* IE10 */

    // *::-ms-backdrop {

        .compare-kits-grid ul.compare-kits-row > li {
            // flex: 1;
            flex: 20%;

            &.legend {
                flex: 100%;
                justify-content: flex-start;
                padding-bottom: 0;
                text-align: left;

                @include breakpoint(above, md) {
                    // flex: 1;
                    flex: 20%;
                    @include responsive-padding(all, $scale: $spacing-kit-grid-cells);
                }

                @include breakpoint(above, lg) {
                    // flex: 1.5;
                    flex: 40%;
                }
            }
        }

    // } /* IE11 */

}
