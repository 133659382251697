/*
 * _slider.scss
 */

.slider-block {
    position: relative;
    background-color: $white;
    border-radius: 30px;
    text-align: center;
    overflow: hidden;

    * {
        color: $pink;
    }

    .slider-single-item {
        position: relative;
        display: none;
        height: 100%;
        @include responsive-padding(x, 2);
        @include responsive-padding(y, 2);

        &:first-of-type {
            display: block;
        }

        .slider-single-item-bg-image {
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background-image: attr(data-background url);
            @include background-cover;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba($purple, 0.72);
            }
        }

        .slide-subhead {
            color: $blue;
        }

        .btn {
            background-size: 202% 100%;

            &:hover,
            &:focus {
                background-position: -98%;
            }
        }

        &.slide-has-image * {
            color: $white;
        }
    }

    ul.slick-dots {
        position: absolute;
        bottom: 24px;
        left: 0;
        right: 0;
        margin-bottom: 0;
        padding-left: 0;

        li {
            width: 10px;
            height: 10px;
            margin: 0 5px;
            list-style-type: none;
            display: inline-block;
            background-color: $white;
            border-radius: 50%;
            font-size: 0;
            opacity: 0.57;

            &.slick-active {
                opacity: 1.0;
            }
        }
    }

    .slider-block-top,
    .slider-block-bottom {

        &::before,
        &::after {
            $slider-block-corner-size: 24px;
            content: '';
            position: absolute;
            z-index: 1;
            width: $slider-block-corner-size;
            height: $slider-block-corner-size;
            background-image: url('../images/store/slider-corner.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: $slider-block-corner-size;

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 32px;
                width: $slider-block-corner-size;
                height: $slider-block-corner-size;
                background-size: $slider-block-corner-size;
            }
        }
    }

    .slider-block-top {

        &::before {
            $slider-block-corner-size: 24px;
            top: $slider-block-corner-size;
            left: $slider-block-corner-size;

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 32px;
                top: $slider-block-corner-size;
                left: $slider-block-corner-size;
            }
        }

        &::after {
            $slider-block-corner-size: 24px;
            top: $slider-block-corner-size;
            right: $slider-block-corner-size;
            transform: rotate(90deg);

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 32px;
                top: $slider-block-corner-size;
                right: $slider-block-corner-size;
            }
        }
    }

    .slider-block-bottom {

        &::before {
            $slider-block-corner-size: 24px;
            bottom: $slider-block-corner-size;
            left: $slider-block-corner-size;
            transform: rotate(-90deg);

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 32px;
                bottom: $slider-block-corner-size;
                left: $slider-block-corner-size;
            }
        }

        &::after {
            $slider-block-corner-size: 24px;
            bottom: $slider-block-corner-size;
            right: $slider-block-corner-size;
            transform: rotate(180deg);

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 32px;
                bottom: $slider-block-corner-size;
                right: $slider-block-corner-size;
            }
        }
    }
}
