/*
 * _image.scss
 */

.wp-block-image {
    position: relative;
    margin: 0;

    img {
        // border-radius: 30px;
        overflow: hidden;
    }

    &.aligncenter,
    figure.aligncenter {
        img {
            margin: 0 auto;
        }

        figcaption {
            text-align: center;
        }
    }

    &.is-style-circle-mask img {
        border-radius: 50%;
    }

    &.has-square-corners img {
        border-radius: 0;
    }

    &.has-checkmarks {

        &::before,
        &::after {
            $slider-block-corner-size: 60px;
            content: '';
            position: absolute;
            z-index: 1;
            left: 10%;
            width: $slider-block-corner-size;
            height: $slider-block-corner-size;
            background-image: url('../images/store/checkmark.svg');
            background-repeat: no-repeat;
            background-position: left center;
            background-size: $slider-block-corner-size;

            // @include breakpoint(above, sm) {
            //     $slider-block-corner-size: 32px;
            //     width: $slider-block-corner-size;
            //     height: $slider-block-corner-size;
            //     background-size: $slider-block-corner-size;
            // }
        }

        &::before {
            $slider-block-corner-size: 60px;
            top: -( $slider-block-corner-size / 2 );
            width: $slider-block-corner-size;
            height: $slider-block-corner-size;
            background-size: $slider-block-corner-size;

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 80px;
                top: -( $slider-block-corner-size / 2 );
                width: $slider-block-corner-size;
                height: $slider-block-corner-size;
                background-size: $slider-block-corner-size;
            }

            @include breakpoint(above, lg) {
                $slider-block-corner-size: 96px;
                top: -( $slider-block-corner-size / 2 );
                width: $slider-block-corner-size;
                height: $slider-block-corner-size;
                background-size: $slider-block-corner-size;
            }
        }

        &::after {
            $slider-block-corner-size: 60px;
            bottom: -( $slider-block-corner-size / 2 );
            width: $slider-block-corner-size;
            height: $slider-block-corner-size;
            background-size: $slider-block-corner-size;

            @include breakpoint(above, sm) {
                $slider-block-corner-size: 80px;
                bottom: -( $slider-block-corner-size / 2 );
                width: $slider-block-corner-size;
                height: $slider-block-corner-size;
                background-size: $slider-block-corner-size;
            }

            @include breakpoint(above, lg) {
                $slider-block-corner-size: 96px;
                bottom: -( $slider-block-corner-size / 2 );
                width: $slider-block-corner-size;
                height: $slider-block-corner-size;
                background-size: $slider-block-corner-size;
            }
        }
    }
}
