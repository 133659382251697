/*
 * _main.scss
 */

body {
  background-color: $white;
  overflow-x: hidden;
}

#app {
    max-width: $site-max-width;
    margin: 0 auto;
    background-color: $white;
    min-height: 100vh;
}

.login .site-main{
    padding-top: 0;
}

.login #app{
    min-height: 100vh;
}

.site-main  {
    @include responsive-padding(top);

    // .my-account &{
    //     padding-top:0;
    // }
}
//remove padding-top on home, my-account
// body.home,
body.my-account,
body.my-account-edit  {
    .site-main {
        padding-top: 0;
        padding-bottom: 0;
    }
}


//mspl homepage h1
.mspl-home-h1{
    max-width: 600px;
    margin: 0 auto;
}
.mspl-home-hero figure{
    margin-bottom: 0;
}
