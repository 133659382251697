/*
 * _footer.scss
 */

@media (min-height: 850px) and (min-width: 365px) {
    .login footer{
        position: absolute;
        width: 100%;
        bottom: 0;
    };
}

.site-footer {
    @include responsive-padding(y);
    padding-left: 0;
    padding-right: 0;
    background-color: $purple;
    color: $purple-light;

    @media (min-height: 1250px) {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    h4 {
        color: $white;
    }

    a {
        text-decoration: underline;
        color: $purple-light;

        &:hover,
        &:focus {
            text-decoration: none;
            color: $white;
        }
    }

    ul.menu {
        padding-left: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;

            span[class^="icon-"] {
                display: inline-block;
                margin-right: 12px;
                transform: translateY(2px);
            }
        }
    }

    ul.social-icons {
        // @include responsive-margin(top);
        padding-left: 0;
        list-style-type: none;

        li {
            display: inline-block;
            // @include responsive-margin(right, $scale: $spacing-default);
            margin-right:2rem;
            a {
                text-decoration: none;
                font-size: 24px;
            }
            &:last-of-type{
                margin-right:0;
            }

            @media (max-width: 410px) {
                margin-right: 20px;
            }
            
            @media (max-width: 345px) {
                margin-right: 5px;
            }
        }
    }
}

.site-footer-widget-area {

}

.site-footer-bottom {
    // @include responsive-margin(top, $scale: $spacing-columns);
    // @include responsive-padding(top, $scale: $spacing-columns);
    // border-top: 1px solid $purple-medium;

    ul.menu {
        display: inline-block;
        margin-bottom: 0;

        li {
            display: inline-block;
            @include responsive-margin(right, $scale: $spacing-columns);
            margin-bottom: 0;
        }
    }

    .cluejay-tm {
        display: block;
        font-family: $font-secondary-bold;
        font-weight: normal;
        color: $white;

    }
}

.site-info .legal {
	text-align: center;
}

.footer-navigation {
	display: inline-block;

	ul {
		margin-bottom: 0;
		padding-left: 0;
	}

	li {
		display: inline-block;
		list-style-type: none;
	}
}

