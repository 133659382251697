/*
 * _spacer.scss
 */

.wp-block-spacer {

    &.responsive-spacer {

    	@each $key, $value in $grid-breakpoints {
            @include breakpoint(above, $key) {
    			height: map-get($spacing-vertical, $key) !important;
    		}
    	}
    }

    &.responsive-spacer-double {

        @each $key, $value in $grid-breakpoints {
            @include breakpoint(above, $key) {
                height: calc( #{map-get($spacing-vertical, $key)} * 2 ) !important;
            }
        }
    }
}

.section-margin-all {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            margin-top: map-get($spacing-vertical, $key) !important;
            margin-bottom: map-get($spacing-vertical, $key) !important;
            margin-left: map-get($spacing-horizontal, $key) !important;
            margin-right: map-get($spacing-horizontal, $key) !important;
        }
    }
}

.section-margin-vertical {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            margin-top: map-get($spacing-vertical, $key) !important;
            margin-bottom: map-get($spacing-vertical, $key) !important;
        }
    }
}

.section-margin-horizontal {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            margin-left: map-get($spacing-horizontal, $key) !important;
            margin-right: map-get($spacing-horizontal, $key) !important;
        }
    }
}

.section-margin-negative-horizontal {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            margin-left: -( map-get($spacing-horizontal, $key) ) !important;
            margin-right: -( map-get($spacing-horizontal, $key) ) !important;
        }
    }
}

.section-padding-all {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            padding-top: map-get($spacing-vertical, $key) !important;
            padding-bottom: map-get($spacing-vertical, $key) !important;
            padding-left: map-get($spacing-horizontal, $key) !important;
            padding-right: map-get($spacing-horizontal, $key) !important;
        }
    }
}

.section-padding-vertical {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            padding-top: map-get($spacing-vertical, $key) !important;
            padding-bottom: map-get($spacing-vertical, $key) !important;
        }
    }
}

.section-padding-horizontal {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            padding-left: map-get($spacing-horizontal, $key) !important;
            padding-right: map-get($spacing-horizontal, $key) !important;
        }
    }
}

.section-padding-negative-horizontal {

    @each $key, $value in $grid-breakpoints {
        @include breakpoint(above, $key) {
            padding-left: -( map-get($spacing-horizontal, $key) ) !important;
            padding-right: -( map-get($spacing-horizontal, $key) ) !important;
        }
    }
}

.signs-you-should-test-outter {
    display: grid !important;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 1200px) {
        margin-left: 30px;
    }
    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
    @media (max-width: 450px) {
        margin-left: 0rem !important;
        padding: 0 0 0 15px !important;
    }
}

.signs-you-should-test-inner {
    display: grid;
    justify-content: end;
    margin: 15px 15px 15px 0;
    
    h4 {
        font-weight: 400 !important;
    }

    @media (max-width: 768px) {
        justify-content: start;
        padding-top: 1rem !important;
        margin-left: 0;
    }
}