/*
 * _accordion.scss
 */

.accordion-block {
    width: 100%;
    @include responsive-margin(y);
    border-bottom: 1px solid $gray-light;

    > * {
        width: 100%;
    }
}

.accordion-item {
	position: relative;
	border-top: 1px solid $gray-light;

    &:last-of-type {
        border-bottom: 1px solid $gray-light;
    }

	.accordion-item--heading {
		position: relative;
		display: flex;
		align-items: flex-start;
		margin: 0;
		padding: 20px 46px 20px 0;
		background-color: $white;
        color: $gray;
		cursor: pointer;
		transition: color $default-transition-time ease-in-out;


		span {
			display: inline-block;
			// transform: translateY(2px);
		}

		&::after {
			content: "\e91a";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			display: block;
			width: 32px;
			height: 32px;
			margin: auto;
      background-color: $gray;
			border: none;
			border-radius: 50%;
			text-align: center;
			line-height: 32px;
			font-family: $icon-font-family;
			font-size: 12px;
			font-weight: 400;
			color: $white;
			transform: rotate(0);
			transform-origin: center;
            transition: background-color $default-transition-time ease-in-out,
                        color $default-transition-time ease-in-out,
                        transform $default-transition-time ease-in-out;
		}

		&:hover {
			color: $purple;

			&::after {
				background-color: $purple;
				color: $white;
			}
		}
	}

	.accordion-item--content {
      display: none;
      padding: 0 0 20px;
      overflow: hidden;

      a {
  	   color: $purple;

      	&:hover,
      	&:focus {
      		color: $gray;
      	}
      }
  }

  &.active {

    .accordion-item--heading{
	   	color: $purple;

		  &::after {
        content: "\e921";
        background-color: $purple;
        // line-height: 118%;
				color: $white;
				transform: rotate(180deg);
			}
    }
	}
}

