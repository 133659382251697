.card {
    position: relative;
    // @include responsive-padding(all, $scale: $spacing-card);
    border-color: transparent;
    border-radius: 8px;
    box-shadow: 0 -1px 2px rgba(0,0,0,0.025),
                0 -2px 4px rgba(0,0,0,0.025),
                0 1px 2px rgba(0,0,0,0.025),
                0 2px 4px rgba(0,0,0,0.025),
                0 4px 8px rgba(0,0,0,0.025),
                0 8px 16px rgba(0,0,0,0.025),
                0 16px 32px rgba(0,0,0,0.025);

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include responsive-margin(x, $scale: $spacing-card);
        @include responsive-padding(top, $scale: $spacing-card);
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-bottom: 0 !important;
        background-color: $white
    }

    .card-body {
        @include responsive-padding(all, $scale: $spacing-card);
    }

    &.has-new-flag::before {
        content: 'New!';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        margin: 1rem;
        padding-left: 30px;
        background-image: url('../images/store/chevron.svg');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 15px 24px;
        font-family: $font-secondary-bold;
        font-weight: bold;
        font-size: 16px;

        // @each $key, $value in $grid-breakpoints {
        //     @include breakpoint(above, $key) {
        //         margin: calc( #{map-get($spacing-card, $key)} / 2 ) !important;
        //     }
        // }

        @include breakpoint(above, sm) {
            font-size: 18px;
        }

        @include breakpoint(above, lg) {
            font-size: 20px;
        }
    }

    &.has-coming-soon-flag::before {
        content: 'Coming Soon';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        padding: 3px 0;
        background-color: $pink;
        border-radius: 8px 8px 0 0;
        border: 1px solid $pink;
        text-align: center;
        text-transform: uppercase;
        font-family: $font-secondary-bold;
        font-weight: bold;
        font-size: 12px;
        color: $white;

        // @each $key, $value in $grid-breakpoints {
        //     @include breakpoint(above, $key) {
        //         margin: calc( #{map-get($spacing-card, $key)} / 2 ) !important;
        //     }
        // }

        @include breakpoint(above, lg) {
            font-size: 14px;
        }
    }
}

