.vue-loader{
    &.true{

    }
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
    background:rgba(255,255,255,.5);
    text-align: center;
    z-index: 250;
    position: absolute;
    top:0;
    left:0;

    .loader {
      border: 16px solid #f3f3f3; /* Light grey */
      border-top: 16px solid #3498db; /* Blue */
      border-radius: 50%;
      width: 120px;
      height: 120px;
      animation: spin 1s linear infinite;
  }

  @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
  }
}
