.v-click, .vue-anchor{
    cursor: pointer;
}
.height-100-percent{
    height:100%;
    min-height: 100%;
    min-height: 100vh;
}
.sort-icon{
     display: inline-block;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.max-width-half{
    max-width:50%
}
.rounded{
    border-radius: 50% !important;
}
.pet-thumbnail {
    height:50px;
    width:50px;
}
.pet-edit-thumbnail{
    height:250px;
    width:250px;
}
