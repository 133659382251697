/*
 * _image-card-grid.scss
 */

.image-card-grid {
    margin-top: -2rem;

    .image-card-grid-items {
        width: 100%;
        margin: 0 auto;

        .card {
            margin-top: 2rem;
            padding-top: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 90%;
        }

        @include breakpoint(above, sm) {

            .card {
                margin-top: 0;
            }
        }

        @include breakpoint(above, md) {
            width: 80%;

            .card {
                margin-top: 2rem;
            }
        }
    }

    @include breakpoint(above, sm) {
        margin-top: 0;
    }

    @include breakpoint(above, md) {
        margin-top: -2rem;
    }
}
