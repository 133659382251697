/*
 * _core.scss
 */

// =============================================================================
// Global Styles
// -----------------------------------------------------------------------------

:root {
  --scrollbar-width: 17px;
}

// body {
//     min-width: 320px;
// }

.site {
  position: relative;
  max-width: $site-max-width;
  margin: 0 auto;
  background-color: $white;
  // overflow-x: hidden;
}

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}


// =============================================================================
// Font Styles
// -----------------------------------------------------------------------------

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;

}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  bottom: -0.25em;
}
